export const WORDS = [
  'ballas',
  'otak',
  'titania',
  'grinner',
  'deimos',
  'sentient',
  'phobos',
  'ambulas',
  'nekros',
  'mag',
  'venus',
  'impact',
  'onkko',
  'zephyr',
  'heat',
  'lua',
  'blast',
  'corrosive',
  'konzu',
  'darvo',
  'natah',
  'infested',
  'harrow',
  'sevagoth',
  'yareli',
  'inaros',
  'mesa',
  'sedna',
  'ash',
  'gara',
  'radiation',
  'vor',
  'banshee',
  'hildryn',
  'mirage',
  'baruuk',
  'necralisk',
  'hunhow',
  'vauban',
  'viral',
  'nova',
  'mars',
  'ceres',
  'limbo',
  'equinox',
  'eris',
  'valkyr',
  'trinity',
  'revenant',
  'khora',
  'europa',
  'nyx',
  'sergeant',
  'uranus',
  'cold',
  'clem',
  'lotus',
  'magnetic',
  'fortuna',
  'cetus',
  'salvage',
  'razorback',
  'orokin',
  'earth',
  'mercury',
  'simaris',
  'juggernaut',
  'gauss',
  'aladv',
  'garuda',
  'pluto',
  'erra',
  'hydroid',
  'maroo',
  'saryn',
  'ivara',
  'gyre',
  'veso',
  'ember',
  'toxin',
  'rhino',
  'loid',
  'zariman',
  'octavia',
  'frost',
  'wukong',
  'nezha',
  'chroma',
  'volt',
  'nefanyo',
  'void',
  'baro',
  'loki',
  'narmer',
  'raptors',
  'gas',
  'neptune',
  'tenno',
  'excalibur',
  'slash',
  'teshin',
  'corpus',
  'puncture',
  'stalker',
  'nidus',
  'jackal',
  'grendel',
  'caliban',
  'noranight',
  'hyenapack',
  'wisp',
  'oberon',
  'atlas',
  'protea',
  'xaku',
  'ordis',
  'lechkril',
'excavation',
'hemocyte',
'vayhek',
'styanax',
'lavos',
'nihil',
'zenurik',
'veso',
'phorid',
'vazarin',
'saturn',
'perrin',
'ostron',
'teralyst',
'arbiters',
'gantulyst',
'cascade',
'lephantis',
'disruption',
'assault',
'armageddon',
'kuvalich',
'madurai',
'freeroam',
'meridian',
'rescue',
'defection',
'quills',
'tylregor',
'conclave',
'holdfasts',
'ventkids',
'survival',
'hijack',
'sargasruk',
'defense',
'arena',
'newloka',
'naramon',
'unairu',
'dojo',
'ropalolyst',
'hydrolyst',
'entrati',
'redveil',
'suda',
'junction',
'flood',
'spy',
'necraloid',
'solaris',
'nightwave',
'voxsolaris',
'capture',
]