import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="mb-3 text-sm text-gray-500 dark:text-gray-300">
        Guess the word in 6 tries. After each guess, the color of the tiles will
        change to show how close your guess was to the word.
      </p>
      <hr style={{borderColor: 'lightblue'}}></hr>
      <p className="mt-3 mb-3 text-sm text-gray-500 dark:text-gray-300">
        <b>Words included: </b>Warframes, Starcharts locations, NPCs, Factions, Bosses, Damage types,
        Syndicates, Missions types, Focus Schools,
      </p>
      <hr style={{borderColor: 'lightblue'}}></hr>
      <div className=" flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="F"
          status="correct"
        />
        <Cell value="R" />
        <Cell value="O" />
        <Cell value="S" />
        <Cell value="T" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter F is in the word and in the correct spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="A" />
        <Cell value="L" />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="A"
          status="present"
        />
        <Cell value="D" />
        <Cell value="V" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter A is in the word but in the wrong spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="K" />
        <Cell value="H" />
        <Cell value="O" />
        <Cell isRevealing={true} isCompleted={true} value="R" status="absent" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter R is not in the word in any spot.
      </p>
     <br></br>
      <p className="italic text-sm text-gray-500 dark:text-gray-300">
        Framedle by <a href="https://github.com/PanSalam" className="underline font-bold">Pan_Salam.</a>
      </p>

      <p style={{marginBottom:10}} className="mt-6 italic text-sm text-gray-500 dark:text-gray-300">
        This is an open source version of the word guessing game we all know and
        love -{' '}
        <a
          href="https://github.com/cwackerfuss/react-wordle"
          className="underline font-bold"
        >
          check out the code here
        </a>{' '}
      </p>
      <hr style={{borderColor: 'darkgray'}}></hr>
      <hr style={{borderColor: 'darkgray'}}></hr>
      <p className="mt-3 italic text-sm text-gray-500 dark:text-gray-300">
      'Framedle' isn't endorsed by Digital Extremes and doesn't reflect the views or opinions of Digital Extremes or anyone officially involved in producing or managing Warframe. Warframe and Digital Extremes are trademarks or registered trademarks of Digital Extremes Ltd. Warframe © Digital Extremes Ltd.
      </p>
    </BaseModal>
  )
}
